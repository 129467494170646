// const BACKEND = "http://localhost:8080";

const BACKEND = "http://43.143.230.82:80";

const request = (options) => {
  const headers = new Headers();

  // if ()
  console.log(options.url);
  if (options.url.endsWith("pdf")) {
    headers.append("Content-Type", "text/pdf");
    console.log("in pdf");
  } else {
    headers.append("Content-Type", "application/json");
    console.log("in json");
  }

  if (localStorage.getItem("accessToken")) {
    headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("accessToken")
    );
  }
  console.log(headers.get("Authorization"));
  const defaults = { headers: headers };
  options = Object.assign({}, defaults, options);
  console.log(options);
  return fetch(options.url, options).then((response) =>
    response.json().then((json) => {
      console.log(response);
      if (!response.ok) {
        return Promise.reject(json);
      }
      return json;
    })
  );
};

const requestt = (options) => {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  if (localStorage.getItem("accessToken")) {
    headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("accessToken")
    );
  }
  console.log(headers.get("Authorization"));
  const defaults = { headers: headers };
  options = Object.assign({}, defaults, options);
  console.log(options);
  return fetch(options.url, options).then((response) =>
    response.blob().then((json) => {
      console.log("in blog");
      console.log(response);
      if (!response.ok) {
        return Promise.reject(json);
      }
      return json;
    })
  );
};

export function login(loginRequest) {
  return request({
    url: BACKEND + "/signin",
    method: "POST",
    body: JSON.stringify(loginRequest),
  });
}

export function signup(signupRequest) {
  return request({
    url: BACKEND + "/users",
    method: "POST",
    body: JSON.stringify(signupRequest),
  });
}

export function addCart(userId, productId) {
  return request({
    url: BACKEND + "/cart/add/" + userId + "/" + productId,
    method: "POST",
  });
}

export function getCart(userId) {
  return request({
    url: BACKEND + "/cart/" + userId,
    method: "GET",
  });
}

export function deleteCart(cartId) {
  return request({
    url: BACKEND + "/cart/delete/" + cartId,
    method: "POST",
  });
}

export function modifyCart(cartId, quantity) {
  return request({
    url: BACKEND + "/cart/updateQuantity/" + cartId + "/" + quantity,
    method: "POST",
  });
}

export function createProduct(createRequest, image) {
  return request({
    url: BACKEND + "/products/add",
    method: "POST",
    body: createRequest,
  });
}

//create comment!!!!!!
export function createComment(createRequest, image) {
  return request({
    url: BACKEND + "/comments/add",
    method: "POST",
    body: createRequest,
  });
}

// get single product
export function getSingleProduct(productId) {
  return request({
    url: BACKEND + "/product/" + productId,
    method: "GET",
  });
}

// get single product comment
export function getSingleProductComment(productId) {
  return request({
    url: BACKEND + "/commentOfProduct/" + productId,
    method: "GET",
  });
}

// get single user
export function getSingleUser(userId) {
  return request({
    url: BACKEND + "/user/" + userId,
    method: "GET",
  });
}

// delete single product
export function deleteProduct(productId) {
  return request({
    url: BACKEND + "/product/delete/" + productId,
    method: "POST",
  });
}

// delete single comment
export function deleteComment(commentId) {
  return request({
    url: BACKEND + "/comment/delete/" + commentId,
    method: "POST",
  });
}

// get user comment
export function getUserComment(userId) {
  return request({
    url: BACKEND + "/commentOfUser/" + userId,
    method: "GET",
  });
}

export function getCurrentUser() {
  if (!localStorage.getItem("accessToken")) {
    return Promise.reject("No access token set.");
  }
  console.log("have token");
  return request({
    url: BACKEND + "/users/me",
    method: "GET",
  });
}

export function getUsers() {
  if (!localStorage.getItem("accessToken")) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: BACKEND + "/users/summaries",
    method: "GET",
  });
}

// Haoyang's fake api
export function getProducts() {
  if (!localStorage.getItem("accessToken")) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: "https://randomuser.me/api/?results=20",
    method: "GET",
  });
}

// get all products
export function getProductss() {
  if (!localStorage.getItem("accessToken")) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: BACKEND + "/product/main",
    method: "GET",
  });
}

// get products from a user
export function getUserProduct(userId) {
  if (!localStorage.getItem("accessToken")) {
    return Promise.reject("No access token set.");
  }
  return request({
    url: BACKEND + "/productOfUser/" + userId,
    method: "GET",
  });
}

// delete user
export function deleteUser(deleteRequest) {
  return request({
    url: BACKEND + "/admin/delete",
    method: "POST",
    body: JSON.stringify(deleteRequest),
  });
}

// modify user
export function modifyUser(modifyUser) {
  return request({
    url: BACKEND + "/admin/modifyProfile",
    method: "POST",
    body: JSON.stringify(modifyUser),
  });
}

// change password
export function changepassword(loginRequest) {
  return request({
    url: BACKEND + "/users/changepassword",
    method: "POST",
    body: JSON.stringify(loginRequest),
  });
}

// modify product
export function modifyProduct(productId, requestBody) {
  return request({
    url: BACKEND + "/product/update/" + productId,
    method: "POST",
    body: JSON.stringify(requestBody),
  });
}

// chatting
export function countNewMessages(senderId, recipientId) {
  if (!localStorage.getItem("accessToken")) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: BACKEND + "/messages/" + senderId + "/" + recipientId + "/count",
    method: "GET",
  });
}

export function findChatMessages(senderId, recipientId) {
  if (!localStorage.getItem("accessToken")) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: BACKEND + "/messages/" + senderId + "/" + recipientId,
    method: "GET",
  });
}

export function findChatMessage(id) {
  if (!localStorage.getItem("accessToken")) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: BACKEND + "/messages/" + id,
    method: "GET",
  });
}

export function addStock(createRequest) {
  return request({
    url: BACKEND + "/stock/add",
    method: "POST",
    body: JSON.stringify(createRequest),
  });
}
export function adjustStock(createRequest) {
  return request({
    url: BACKEND + "/stock/adjust",
    method: "POST",
    body: JSON.stringify(createRequest),
  });
}

export function getAllRecords() {
  if (!localStorage.getItem("accessToken")) {
    return Promise.reject("No access token set.");
  }
  return request({
    url: BACKEND + "/stockRecord/getAll",
    method: "GET",
  });
}

export function getAllStock() {
  if (!localStorage.getItem("accessToken")) {
    return Promise.reject("No access token set.");
  }
  return request({
    url: BACKEND + "/stock/getAll",
    method: "GET",
  });
}

export function addStockOut(createRequest) {
  return request({
    url: BACKEND + "/stockOut/add",
    method: "POST",
    body: JSON.stringify(createRequest),
  });
}

export function getAllOutRecords() {
  if (!localStorage.getItem("accessToken")) {
    return Promise.reject("No access token set.");
  }
  return request({
    url: BACKEND + "/stockOut/getAll",
    method: "GET",
  });
}

export function getStockOutById(createRequest) {
  if (!localStorage.getItem("accessToken")) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: BACKEND + "/stockOut/get",
    method: "POST",
    body: JSON.stringify(createRequest),
  });
}

export function editStockOut(createRequest) {
  return request({
    url: BACKEND + "/stockOut/edit",
    method: "POST",
    body: JSON.stringify(createRequest),
  });
}

export function getAllAdjustRecords() {
  if (!localStorage.getItem("accessToken")) {
    return Promise.reject("No access token set.");
  }
  return request({
    url: BACKEND + "/stockAdjust/getAll",
    method: "GET",
  });
}

export function getSummary(createRequest) {
  if (!localStorage.getItem("accessToken")) {
    return Promise.reject("No access token set.");
  }
  return request({
    url: BACKEND + "/stockOut/summary",
    method: "POST",
    body: JSON.stringify(createRequest),
  });
}

export function getStockPDF() {
  if (!localStorage.getItem("accessToken")) {
    return Promise.reject("No access token set.");
  }
  return requestt({
    url: BACKEND + "/stock/export-to-pdf",
    method: "GET",
  });
}

export function getStockOutPDF(id) {
  if (!localStorage.getItem("accessToken")) {
    return Promise.reject("No access token set.");
  }
  return requestt({
    url: BACKEND + "/stockOut/export-to-pdf/" + id,
    method: "GET",
  });
}

export function getSummaryPDF(createRequest) {
  if (!localStorage.getItem("accessToken")) {
    return Promise.reject("No access token set.");
  }
  return requestt({
    url: BACKEND + "/stockOut/summary/export-to-pdf",
    method: "POST",
    body: JSON.stringify(createRequest),
  });
}

export function withdrawOutRecord(id) {
  if (!localStorage.getItem("accessToken")) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: BACKEND + "/stockOut/withdraw/" + id,
    method: "GET",
  });
}
