import React, { useEffect, useState } from "react";
import { Form, Input, Button, notification, Select } from "antd";
import { useRecoilState } from "recoil";
import { loggedInUser, recordss } from "../atom/globalState";
import { getAllStock, addStock } from "../util/ApiUtil";
import { Cascader, InputNumber } from "antd";
import locale from "antd/es/date-picker/locale/zh_CN";
import { Radio } from "antd";

const StockIn = (props) => {
  const [currentUser, setLoggedInUser] = useRecoilState(loggedInUser);
  const [stockRecords, setStockRecords] = useRecoilState(recordss);
  const [form] = Form.useForm();
  const [requiredMark, setRequiredMarkType] = useState("false");

  const loadStocks = () => {
    getAllStock()
      .then((response) => {
        console.log(stockRecords);
        setStockRecords(response);
      })
      .catch((error) => {
        console.log(error);
        const code = error.status;
        props.history.push("/error/" + code);
      });
  };

  useEffect(() => {
    if (localStorage.getItem("accessToken") === null) {
      props.history.push("/login");
    }
    loadStocks();
  }, []);

  const onRequiredTypeChange = ({ requiredMarkValue }) => {
    if (requiredMarkValue != null) {
      setRequiredMarkType(requiredMarkValue);
    }
  };
  const _addStock = (values) => {
    console.log(values.type);

    const info = {
      inStockPerson: values.inStockPerson,
      inStockVolume: values.inStockVolume,
      type: Array.isArray(values.type)
        ? values.type[values.type.length - 1]
        : values.type,
      isNew: values.requiredMarkValue,
      category: values.category,
      note: values.note,
    };
    addStock(info)
      .then((response) => {
        notification.success({
          message: "Success",
          description: "提交成功！",
        });
        props.history.push("/");
      })
      .catch((error) => {
        notification.error({
          message: "Error",
          description: error.message || "提交失败！",
        });
      });
  };

  const onChange = (value) => {
    console.log(`selected ${value}`);
  };
  const onSearch = (value) => {
    console.log("search:", value);
  };

  const logout = () => {
    localStorage.removeItem("accessToken");
    props.history.push("/login");
  };

  return (
    <div class="background-color">
      <nav class="nav-container">
        <a class="logo" href="/Stock">
          {" "}
          三赢库存系统 | {currentUser.username}
        </a>

        <div class="nav-item">
          <a href="StockIn">入库</a>
          <a href="/StockOut">出库</a>
          <a href="/StockInRecord">入货单记录</a>
          <a href="/StockOutRecord">出货单记录</a>
          <a href="/Stock">库存</a>
          <a href="/Summary">生产汇总</a>
          <a href="#" onClick={logout}>
            退出{" "}
          </a>
        </div>
      </nav>

      <section>
        <h1 class="label">入库单</h1>
        <hr class="solid"></hr>
        <div>
          <div class="content">
            <Form
              form={form}
              style={{ width: 400 }}
              layout="vertical"
              initialValues={{
                requiredMarkValue: requiredMark,
              }}
              onValuesChange={onRequiredTypeChange}
              requiredMark={requiredMark}
              onFinish={_addStock}
            >
              <Form.Item label="入库/新建" name="requiredMarkValue">
                <Radio.Group>
                  <Radio.Button value={"false"}>入库</Radio.Button>
                  <Radio.Button value={"true"}>新建</Radio.Button>
                </Radio.Group>
              </Form.Item>

              {requiredMark == "true" ? (
                <Form.Item
                  name="category"
                  label="材料类别"
                  rules={[
                    {
                      required: true,
                      message: "请填写材料类别！",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={[
                      {
                        value: "原料",
                        label: "原料",
                      },
                      {
                        value: "再生料",
                        label: "再生料",
                      },
                      {
                        value: "蜡",
                        label: "蜡",
                      },
                      {
                        value: "助剂",
                        label: "助剂",
                      },
                      {
                        value: "降解料",
                        label: "降解料",
                      },
                      {
                        value: "降解助剂",
                        label: "降解助剂",
                      },
                      {
                        value: "黄",
                        label: "黄",
                      },
                      {
                        value: "桔",
                        label: "桔",
                      },
                      {
                        value: "红",
                        label: "红",
                      },
                      {
                        value: "紫",
                        label: "紫",
                      },
                      {
                        value: "蓝",
                        label: "蓝",
                      },
                      {
                        value: "绿",
                        label: "绿",
                      },
                      {
                        value: "白",
                        label: "白",
                      },
                      {
                        value: "灰",
                        label: "灰",
                      },
                      {
                        value: "黑",
                        label: "黑",
                      },
                      {
                        value: "包装袋",
                        label: "包装袋",
                      },
                      {
                        value: "其他",
                        label: "其他",
                      },
                    ]}
                  />
                </Form.Item>
              ) : null}

              <Form.Item
                name="type"
                label="材料名称"
                rules={[
                  {
                    required: true,
                    message: "请填写材料名称！",
                  },
                ]}
              >
                {requiredMark === "false" ? (
                  <Cascader
                    showSearch
                    optionFilterProp="children"
                    onChange={onChange}
                    onSearch={onSearch}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    // options={stockRecords.map((item) => ({
                    //   value: item.stockName,
                    //   label: item.stockName,
                    // }))}

                    options={[
                      {
                        value: "原料",
                        label: "原料",
                        children:
                          stockRecords != null
                            ? stockRecords
                                .filter((search) =>
                                  search.category.includes("原料")
                                )
                                .map((item) => ({
                                  value: item.stockName,
                                  label: item.stockName,
                                }))
                            : null,
                      },
                      {
                        value: "再生料",
                        label: "再生料",
                        children:
                          stockRecords != null
                            ? stockRecords
                                .filter((search) =>
                                  search.category.includes("再生料")
                                )
                                .map((item) => ({
                                  value: item.stockName,
                                  label: item.stockName,
                                }))
                            : null,
                      },
                      {
                        value: "蜡",
                        label: "蜡",
                        children:
                          stockRecords != null
                            ? stockRecords
                                .filter((search) =>
                                  search.category.includes("蜡")
                                )
                                .map((item) => ({
                                  value: item.stockName,
                                  label: item.stockName,
                                }))
                            : null,
                      },
                      {
                        value: "助剂",
                        label: "助剂",
                        children:
                          stockRecords != null
                            ? stockRecords
                                .filter((search) =>
                                  search.category.includes("助剂")
                                )
                                .map((item) => ({
                                  value: item.stockName,
                                  label: item.stockName,
                                }))
                            : null,
                      },
                      {
                        value: "降解料",
                        label: "降解料",
                        children:
                          stockRecords != null
                            ? stockRecords
                                .filter((search) =>
                                  search.category.includes("降解料")
                                )
                                .map((item) => ({
                                  value: item.stockName,
                                  label: item.stockName,
                                }))
                            : null,
                      },
                      {
                        value: "降解助剂",
                        label: "降解助剂",
                        children:
                          stockRecords != null
                            ? stockRecords
                                .filter((search) =>
                                  search.category.includes("降解助剂")
                                )
                                .map((item) => ({
                                  value: item.stockName,
                                  label: item.stockName,
                                }))
                            : null,
                      },
                      {
                        value: "黄",
                        label: "黄",
                        children:
                          stockRecords != null
                            ? stockRecords
                                .filter((search) =>
                                  search.category.includes("黄")
                                )
                                .map((item) => ({
                                  value: item.stockName,
                                  label: item.stockName,
                                }))
                            : null,
                      },
                      {
                        value: "桔",
                        label: "桔",
                        children:
                          stockRecords != null
                            ? stockRecords
                                .filter((search) =>
                                  search.category.includes("桔")
                                )
                                .map((item) => ({
                                  value: item.stockName,
                                  label: item.stockName,
                                }))
                            : null,
                      },
                      {
                        value: "红",
                        label: "红",
                        children:
                          stockRecords != null
                            ? stockRecords
                                .filter((search) =>
                                  search.category.includes("红")
                                )
                                .map((item) => ({
                                  value: item.stockName,
                                  label: item.stockName,
                                }))
                            : null,
                      },
                      {
                        value: "紫",
                        label: "紫",
                        children:
                          stockRecords != null
                            ? stockRecords
                                .filter((search) =>
                                  search.category.includes("紫")
                                )
                                .map((item) => ({
                                  value: item.stockName,
                                  label: item.stockName,
                                }))
                            : null,
                      },
                      {
                        value: "蓝",
                        label: "蓝",
                        children:
                          stockRecords != null
                            ? stockRecords
                                .filter((search) =>
                                  search.category.includes("蓝")
                                )
                                .map((item) => ({
                                  value: item.stockName,
                                  label: item.stockName,
                                }))
                            : null,
                      },
                      {
                        value: "绿",
                        label: "绿",
                        children:
                          stockRecords != null
                            ? stockRecords
                                .filter((search) =>
                                  search.category.includes("绿")
                                )
                                .map((item) => ({
                                  value: item.stockName,
                                  label: item.stockName,
                                }))
                            : null,
                      },
                      {
                        value: "白",
                        label: "白",
                        children:
                          stockRecords != null
                            ? stockRecords
                                .filter((search) =>
                                  search.category.includes("白")
                                )
                                .map((item) => ({
                                  value: item.stockName,
                                  label: item.stockName,
                                }))
                            : null,
                      },
                      {
                        value: "灰",
                        label: "灰",
                        children:
                          stockRecords != null
                            ? stockRecords
                                .filter((search) =>
                                  search.category.includes("灰")
                                )
                                .map((item) => ({
                                  value: item.stockName,
                                  label: item.stockName,
                                }))
                            : null,
                      },
                      {
                        value: "黑",
                        label: "黑",
                        children:
                          stockRecords != null
                            ? stockRecords
                                .filter((search) =>
                                  search.category.includes("黑")
                                )
                                .map((item) => ({
                                  value: item.stockName,
                                  label: item.stockName,
                                }))
                            : null,
                      },
                      {
                        value: "包装袋",
                        label: "包装袋",
                        children:
                          stockRecords != null
                            ? stockRecords
                                .filter((search) =>
                                  search.category.includes("包装袋")
                                )
                                .map((item) => ({
                                  value: item.stockName,
                                  label: item.stockName,
                                }))
                            : null,
                      },
                      {
                        value: "其他",
                        label: "其他",
                        children:
                          stockRecords != null
                            ? stockRecords
                                .filter((search) =>
                                  search.category.includes("其他")
                                )
                                .map((item) => ({
                                  value: item.stockName,
                                  label: item.stockName,
                                }))
                            : null,
                      },
                    ]}
                  />
                ) : (
                  <Input />
                )}
              </Form.Item>

              <Form.Item
                name="inStockVolume"
                label="入库量"
                rules={[
                  {
                    required: true,
                    message: "请填写大于零的入库量！",
                    type: "number",
                    min: 0,
                  },
                ]}
              >
                <InputNumber locale={locale} style={{ width: 400 }} />
              </Form.Item>

              <Form.Item name="inStockPerson" label="入库人">
                <Input />
              </Form.Item>

              <Form.Item name="note" label="备注">
                <Input />
              </Form.Item>

              <Form.Item>
                <Button
                  shape="round"
                  htmlType="submit"
                  className="login-form-button"
                >
                  提交
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </section>
      <footer>
        <p>@2023三赢库存</p>
      </footer>
    </div>
  );
};

export default StockIn;
