import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import { loggedInUser, stocks } from "../atom/globalState";
import { Table } from "antd";
import { getAllStock, getCurrentUser, getStockPDF } from "../util/ApiUtil";
import "./Home.css";
// import PDFDocument from "pdfkit";
// import fs from "fs";

import { useState } from "react";
const Stock = (props) => {
  const [currentUser, setLoggedInUser] = useRecoilState(loggedInUser);
  const [stockRecords, setStockRecords] = useRecoilState(stocks);
  const [query, setQuery] = useState("");
  const fs = require("fs");

  const logout = () => {
    localStorage.removeItem("accessToken");
    props.history.push("/login");
  };

  useEffect(() => {
    if (localStorage.getItem("accessToken") === null) {
      props.history.push("/login");
    }

    if (localStorage.getItem("name") === "Admin") {
      props.history.push("/stockAdmin");
    }
    loadCurrentUser();
    loadStocks();
  }, []);
  const loadCurrentUser = () => {
    getCurrentUser()
      .then((response) => {
        setLoggedInUser(response);
      })
      .catch((error) => {
        const code = error.status;
        props.history.push("/error/" + code);
        console.log(error);
      });
  };

  const loadStocks = () => {
    getAllStock()
      .then((response) => {
        setStockRecords(response);
      })
      .catch((error) => {
        console.log(error);
        const code = error.status;
        props.history.push("/error/" + code);
      });
  };

  // app.get('/', (req, res) => res.download('./file.pdf'))
  const getPDF = () => {
    getStockPDF()
      .then((res) => {
        var data = new Blob([res], { type: "application/pdf" });
        var csvURL = window.URL.createObjectURL(data);
        var tempLink = document.createElement("a");
        tempLink.href = csvURL;
        tempLink.setAttribute("download", "库存.pdf");
        tempLink.click();
      })
      .catch((error) => {
        console.log(error);
        const code = error.status;
        props.history.push("/error/" + code);
      });
  };

  const dS = stockRecords
    .filter(
      (search) =>
        (search.stockName != null
          ? search.stockName.toLowerCase().includes(query)
          : null) ||
        (search.category != null
          ? search.category.toLowerCase().includes(query)
          : null)
    )
    .map((item) => ({
      key: "1",
      材料类别: item.category,
      库存量: item.stockVolume,
      材料名称: item.stockName,
    }));

  const columns = [
    {
      title: "材料类别",
      dataIndex: "材料类别",
      key: "材料类别",
    },
    {
      title: "材料名称",
      dataIndex: "材料名称",
      key: "材料名称",
    },
    {
      title: "库存量",
      dataIndex: "库存量",
      key: "库存量",
    },
  ];

  return (
    <div class="background-color">
      <nav class="nav-container">
        <a class="logo" href="/Stock">
          {" "}
          三赢库存系统 | {currentUser.username}
        </a>

        <div class="nav-item">
          <a href="/StockIn">入库</a>
          <a href="/StockOut">出库</a>
          <a href="/StockInRecord">入货单记录</a>
          <a href="/StockOutRecord">出货单记录</a>
          <a href="/Stock">库存</a>
          <a href="/Summary">生产汇总</a>
          <a href="#" onClick={logout}>
            退出{" "}
          </a>
        </div>
      </nav>

      <section class="product">
        <h1>库存记录</h1>
        <input
          type="text"
          placeholder="搜索材料类别或材料名称"
          className="form-control"
          id="search-engine"
          style={{ width: "50" }}
          onChange={(e) => setQuery(e.target.value)}
        ></input>
        <br></br>
        <br></br>

        <button onClick={getPDF} class="button-34" role="button">
          生成库存记录
        </button>
        <br></br>
        <br></br>
        <br></br>
        <Table dataSource={dS} columns={columns} />
        <br></br>
        <br></br>
      </section>

      <footer>
        <p>@2023三赢库存</p>
        <a href="https://beian.miit.gov.cn/" target="_blank">
          辽公网安备 21080402111380
        </a>

        <a href="https://beian.miit.gov.cn/" target="_blank">
          辽ICP备2023000843号-1
        </a>
      </footer>
    </div>
  );
};

export default Stock;
