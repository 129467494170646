import React, { useEffect, useState } from "react";
import { Form, Input, Button, notification } from "antd";
import { signup } from "../util/ApiUtil";
import "./Signup.css";

const Signup = (props) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("accessToken") !== null) {
      props.history.push("/");
    }
  }, []);

  const register = (values) => {
    console.log(values);
    if (values.language == null) {
      values.language = "en";
    }
    setLoading(true);
    signup(values)
      .then((response) => {
        notification.success({
          message: "Success",
          description:
            "Thank you! You're successfully registered. e Login to continue!",
        });
        props.history.push("/login");
        setLoading(false);
      })
      .catch((error) => {
        notification.error({
          message: "Error",
          description: error.message || "错误！",
        });
        setLoading(false);
      });
  };

  return (
    <div class="login-container">
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.min.css"
        integrity="sha512-xh6O/CkQoPOWDdYTDqeRdPCVd1SpvCA9XXcUnZS2FmJNp1coAFzvtCN9BmamE+4aHK8yyUHUSCcJHgXloTyT2A=="
        crossorigin="anonymous"
        referrerpolicy="no-referrer"
      />
      <i class="fa-solid fa-globe"></i>
      <h1>注册</h1>
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{ remember: true }}
        onFinish={register}
      >
        <Form.Item
          name="username"
          rules={[{ required: true, message: "请填写用户名" }]}
        >
          <Input size="large" placeholder="用户名" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: "请填写密码" }]}
        >
          <Input size="large" type="password" placeholder="密码" />
        </Form.Item>
        <Form.Item
          name="email"
          rules={[{ required: true, message: "请填写邮箱" }]}
        >
          <Input size="large" placeholder="邮箱" />
        </Form.Item>
        <Form.Item
          name="address"
          rules={[{ required: true, message: "请填写地址!" }]}
        >
          <Input size="large" placeholder="地址" />
        </Form.Item>
        <Form.Item>
          <Button
            shape="round"
            size="large"
            htmlType="submit"
            className="login-form-button"
            loading={loading}
          >
            注册
          </Button>
        </Form.Item>
        已经注册过了？ <a href="/login">登陆</a>
      </Form>
    </div>
  );
};

export default Signup;
