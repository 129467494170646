import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Signin from "./signin/Signin";
import Signup from "./signup/Signup";
import Profile from "./profile/Profile";
import Chat from "./chat/Chat";
import Cart from "./cart/Cart";
import Detail from "./detail/Detail";
import ChangePassword from "./changePassword/ChangePassword";
import UserManagement from "./userManagement/UserManagement";
import ProductManagement from "./productManagement/ProductManagement";
import CommentManagement from "./commentManagement/CommentManagement";
import UploadAndDisplayImage from "./homepage/addProduct";
import ModifyUser from "./modifyUser/ModifyUser";
import ModifyProduct from "./modifyProduct/ModifyProduct";
import AddComment from "./homepage/addComment";
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import UserProductManagement from "./userProductManagement/UserProductManagement";
import Chats from "./chat/Chats";
import Error from "./error/error";
import UserCommentManagement from "./userCommentManagemen/UserCommentManagement";
import StockOut from "./homepage/StockOut";
import StockIn from "./homepage/StockIn";
import StockInRecord from "./homepage/StockInRecord";
import StockOutRecord from "./homepage/StockOutRecord";
import StockAdjust from "./homepage/StockAdjustment";

import Stock from "./homepage/stock";
import OutRecordDetail from "./homepage/OutRecordDetail";
import Home from "./homepage/Home";
import StockAdmin from "./homepage/StockAdmin";
import StockAdjustRecord from "./homepage/StockAdjustRecord";
import Summary from "./homepage/summary";

export const AppContext = React.createContext();
const App = (props) => {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route exact path="/" render={(props) => <Stock {...props} />} />
          {/* <Route
            exact
            path="/profile"
            render={(props) => <Profile {...props} />}
          /> */}

          <Route
            exact
            path="/login"
            render={(props) => <Signin {...props} />}
          />
          <Route
            exact
            path="/error/:errorCode"
            render={(props) => <Error {...props} />}
          />
          {/* <Route
            exact
            path="/signup"
            render={(props) => <Signup {...props} />}
          /> */}
          {/* <Route
            exact
            path="/chat/:userId"
            render={(props) => <Chat {...props} />}
          />
          <Route
            exact
            path="/error/:errorCode"
            render={(props) => <Error {...props} />}
          />
          <Route
            exact
            path="/addComment/:productId"
            render={(props) => <AddComment {...props} />}
          />
          <Route exact path="/chats" render={(props) => <Chats {...props} />} />
          <Route exact path="/cart" render={(props) => <Cart {...props} />} />
          <Route
            exact
            path="/detail/:productId"
            render={(props) => <Detail {...props} />}
          /> */}
          {/* <Route
            exact
            path="/ModifyProduct/:productId"
            render={(props) => <ModifyProduct {...props} />}
          />
          <Route
            exact
            path="/changepassword"
            render={(props) => <ChangePassword {...props} />}
          /> */}
          <Route
            exact
            path="/UserManagement"
            render={(props) => <UserManagement {...props} />}
          />
          {/* <Route
            exact
            path="/ProductManagement"
            render={(props) => <ProductManagement {...props} />}
          />
          <Route
            exact
            path="/UserProductManagement"
            render={(props) => <UserProductManagement {...props} />}
          /> */}
          <Route
            exact
            path="/StockOut"
            render={(props) => <StockOut {...props} />}
          />
          <Route
            exact
            path="/StockIn"
            render={(props) => <StockIn {...props} />}
          />

          <Route
            exact
            path="/StockInRecord"
            render={(props) => <StockInRecord {...props} />}
          />
          <Route
            exact
            path="/StockOutRecord"
            render={(props) => <StockOutRecord {...props} />}
          />

          <Route exact path="/Stock" render={(props) => <Stock {...props} />} />
          <Route
            exact
            path="/StockAdmin"
            render={(props) => <StockAdmin {...props} />}
          />
          <Route
            exact
            path="/StockAdjustRecord"
            render={(props) => <StockAdjustRecord {...props} />}
          />
          <Route
            exact
            path="/Summary"
            render={(props) => <Summary {...props} />}
          />

          <Route
            exact
            path="/stockDetail/:id"
            render={(props) => <OutRecordDetail {...props} />}
          />
          <Route
            exact
            path="/StockAdjust"
            render={(props) => <StockAdjust {...props} />}
          />
          {/* 
          <Route
            exact
            path="/CommentManagement/:productId"
            render={(props) => <CommentManagement {...props} />}
          />
          <Route
            exact
            path="/AddProduct"
            render={(props) => <UploadAndDisplayImage {...props} />}
          />
          <Route
            exact
            path="/UserCommentManagement"
            render={(props) => <UserCommentManagement {...props} />}
          />
          <Route
            exact
            path="/ModifyUser"
            render={(props) => <ModifyUser {...props} />}
          /> */}
          {/* 
          <Route exact path="/test" render={(props) => <Home {...props} />} /> */}
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default App;
