import React, { useState } from "react";
import { Form, Input, Button, notification } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { login } from "../util/ApiUtil";
import "./Signin.css";

const Signin = (props) => {
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    setLoading(true);

    login(values)
      .then((response) => {
        localStorage.setItem("accessToken", response.accessToken);
        localStorage.setItem("name", values.username);

        props.history.push("/stock");
        setLoading(false);
      })
      .catch((error) => {
        if (error.status === 401) {
          notification.error({
            message: "Error",
            description: "账号密码不匹配!",
          });
        } else {
          notification.error({
            message: "Error",
            description: error.message || "错误！",
          });
        }
        setLoading(false);
      });
  };

  return (
    <div>
      <section>
        <div class="title">
          <h1>登陆</h1>
          <hr class="solid"></hr>
        </div>
        <div className="login-container">
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            <Form.Item
              name="username"
              rules={[{ required: true, message: "请填写用户名！" }]}
            >
              <Input
                size="large"
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="用户名"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: "请填写密码！" }]}
            >
              <Input
                size="large"
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="密码"
              />
            </Form.Item>
            <Form.Item>
              <Button
                shape="round"
                size="large"
                htmlType="submit"
                className="login-form-button"
                loading={loading}
              >
                登陆
              </Button>
            </Form.Item>
          </Form>
        </div>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
      </section>

      <footer>
        <p>@2023三赢库存</p>
        <a href="https://beian.miit.gov.cn/" target="_blank">
          辽公网安备 21080402111380
        </a>

        <a href="https://beian.miit.gov.cn/" target="_blank">
          辽ICP备2023000843号-1
        </a>
      </footer>
    </div>
  );
};

export default Signin;
