import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import { loggedInUser, stockOutRecords } from "../atom/globalState";
import { getAllOutRecords, getStockOutPDF } from "../util/ApiUtil";
import "./Home.css";
import { Button, Table, Badge } from "antd";

import { useState } from "react";
import moment from "moment";
const StockOutRecord = (props) => {
  const [currentUser, setLoggedInUser] = useRecoilState(loggedInUser);
  const [stockRecords, setStockRecords] = useRecoilState(stockOutRecords);
  const [query, setQuery] = useState("");

  const logout = () => {
    localStorage.removeItem("accessToken");
    props.history.push("/login");
  };

  useEffect(() => {
    if (localStorage.getItem("accessToken") === null) {
      props.history.push("/login");
    }

    if (localStorage.getItem("name") === "Admin") {
      props.history.push("/userManagement");
    }

    loadRecords();
  }, []);

  const loadRecords = () => {
    getAllOutRecords()
      .then((response) => {
        console.log(response);
        setStockRecords(response);
      })
      .catch((error) => {
        console.log(error);
        const code = error.status;
        props.history.push("/error/" + code);
      });
  };

  const dS = stockRecords
    .filter(
      (search) =>
        (search.batchNumber != null
          ? search.batchNumber.includes(query)
          : null) ||
        (search.type != null ? search.type.toLowerCase().includes(query) : null)
    )
    .map((record) => ({
      key: "1",
      生产批次: record.batchNumber,
      产品名称: record.type,
      生产量: record.production != null ? record.production : "-",
      执行日期: moment(record.date).format("YYYY-MM-DD"),
      id: record.id,
      status: record.status,
    }));

  const columns = [
    {
      title: "生产批次",
      dataIndex: "生产批次",
      key: "生产批次",
    },
    {
      title: "产品名称",
      dataIndex: "产品名称",
      key: "产品名称",
    },

    {
      title: "生产量",
      dataIndex: "生产量",
      key: "生产量",
    },

    {
      title: "执行日期",
      dataIndex: "执行日期",
      key: "执行日期",
    },
    {
      title: "状态",
      key: "status",
      render: (_, record) =>
        record.status === "withdrawn" ? (
          <Badge status={"error"} text={"已撤销"} />
        ) : (
          <Badge
            status={record.status === "processing" ? "warning" : "success"}
            text={record.status === "processing" ? "生产中" : "完成"}
          />
        ),
    },
    {
      title: "详情",
      dataIndex: "",
      key: "x",
      render: (_, record) => (
        <Button type="primary" href={`/stockDetail/${record.id}`}>
          详情
        </Button>
      ),
    },
  ];

  return (
    <div class="background-color">
      <nav class="nav-container">
        <a class="logo" href="/Stock">
          {" "}
          三赢库存系统 | {currentUser.username}
        </a>

        <div class="nav-item">
          <a href="/StockIn">入库</a>
          <a href="/StockOut">出库</a>
          <a href="/StockInRecord">入货单记录</a>
          <a href="/StockOutRecord">出货单记录</a>
          <a href="/Stock">库存</a>
          <a href="/Summary">生产汇总</a>
          <a href="#" onClick={logout}>
            退出{" "}
          </a>
        </div>
      </nav>

      <section class="product">
        <h1>出货单记录</h1>
        <input
          type="text"
          placeholder="搜索产品名称或者生产批次"
          className="form-control"
          id="search-engine"
          style={{ width: "50" }}
          onChange={(e) => setQuery(e.target.value)}
        ></input>
        <br></br>
        <br></br>

        <br></br>
        <Table dataSource={dS} columns={columns} />
      </section>

      <footer>
        <p>@2023三赢库存</p>
      </footer>
    </div>
  );
};

export default StockOutRecord;
