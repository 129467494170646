import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import { loggedInUser, summary } from "../atom/globalState";
import { getAllRecords, getSummary, getSummaryPDF } from "../util/ApiUtil";
import "./Home.css";
import { Table, DatePicker, Space } from "antd";
import { useState } from "react";
import moment from "moment";
import locale from "antd/es/date-picker/locale/zh_CN";
import "moment/locale/zh-cn";
const { RangePicker } = DatePicker;

const Summary = (props) => {
  const [currentUser, setLoggedInUser] = useRecoilState(loggedInUser);
  const [stockRecords, setStockRecords] = useRecoilState(summary);
  const [query, setQuery] = useState("");
  const [dates, setDates] = useState("");
  // var dates = null;
  const logout = () => {
    localStorage.removeItem("accessToken");
    props.history.push("/login");
  };

  useEffect(() => {
    if (localStorage.getItem("accessToken") === null) {
      props.history.push("/login");
    }

    if (localStorage.getItem("name") === "Admin") {
      props.history.push("/userManagement");
    }

    loadSummary();
  }, []);

  const loadSummary = () => {
    const info = {
      from: null,
      to: null,
    };
    getSummary(info)
      .then((response) => {
        console.log(response);
        setStockRecords(response);
      })
      .catch((error) => {
        console.log(error);
        const code = error.status;
        props.history.push("/error/" + code);
      });
  };
  const onChange = (value, dateString) => {
    setDates(value);
    if (value == null) {
      loadSummary();
      return;
    }

    const info = {
      from: value[0].startOf("day"),
      to: value[1].endOf("day"),
    };

    getSummary(info)
      .then((response) => {
        console.log(response);
        setStockRecords(response);
      })
      .catch((error) => {
        console.log(error);
        const code = error.status;
        props.history.push("/error/" + code);
      });
  };
  const onOk = (value) => {
    console.log("onOk: ", value);
  };

  const getOutPDF = () => {
    var info = {};
    console.log(dates);
    if (dates === "" || dates == null) {
      info = {
        from: null,
        to: null,
      };
    } else {
      info = {
        from: dates[0].startOf("day"),
        to: dates[1].endOf("day"),
      };
    }

    getSummaryPDF(info)
      .then((res) => {
        var data = new Blob([res], { type: "application/pdf" });
        var csvURL = window.URL.createObjectURL(data);
        var tempLink = document.createElement("a");
        tempLink.href = csvURL;
        tempLink.setAttribute("download", "生产汇总.pdf");
        tempLink.click();
      })
      .catch((error) => {
        console.log(error);
        const code = error.status;
        props.history.push("/error/" + code);
      });
  };

  const dS = stockRecords
    .filter(
      (search) =>
        (search.name != null
          ? search.name.toLowerCase().includes(query)
          : null) ||
        (search.batchNumber != null
          ? search.batchNumber.toLowerCase().includes(query)
          : null)
    )
    .map((record) => ({
      key: record.code,
      批号: record.batchNumber,
      成品名称: record.name,
      总预计生产: record.expectedProduction.toFixed(2),
      总实际生产: record.actualProduction.toFixed(2),
      单元用电量:
        record.unitElectricity === "NaN"
          ? "无"
          : record.unitElectricity.toFixed(2),
      总损耗: record.totalLoss.toFixed(2),
      损耗率:
        record.lossRate == "NaN"
          ? "无"
          : (record.lossRate * 100).toFixed(2) + "%",
    }));

  const columns = [
    {
      title: "批号",
      dataIndex: "批号",
      key: "批号",
    },

    {
      title: "成品名称",
      dataIndex: "成品名称",
      key: "成品名称",
    },

    {
      title: "总预计生产（公斤）",
      dataIndex: "总预计生产",
      key: "总预计生产",
    },
    {
      title: "总实际生产（公斤）",
      dataIndex: "总实际生产",
      key: "总实际生产",
    },

    {
      title: "总损耗（公斤）",
      dataIndex: "总损耗",
      key: "总损耗",
    },

    {
      title: "损耗率（百分比）",
      dataIndex: "损耗率",
      key: "损耗率",
    },
    {
      title: "单元用电量（度每公斤）",
      dataIndex: "单元用电量",
      key: "单元用电量",
    },
  ];

  return (
    <div class="background-color">
      <nav class="nav-container">
        <a class="logo" href="/Stock">
          {" "}
          三赢库存系统 | {currentUser.username}
        </a>

        <div class="nav-item">
          <a href="/StockIn">入库</a>
          <a href="/StockOut">出库</a>
          <a href="/StockInRecord">入货单记录</a>
          <a href="/StockOutRecord">出货单记录</a>
          <a href="/Stock">库存</a>
          <a href="/Summary">生产汇总</a>
          <a href="#" onClick={logout}>
            退出{" "}
          </a>
        </div>
      </nav>

      <section class="product">
        <h1>生产汇总</h1>
        <input
          type="text"
          placeholder="搜索批号或成品名"
          className="form-control"
          id="search-engine"
          style={{ width: "50" }}
          onChange={(e) => setQuery(e.target.value)}
        ></input>
        <br></br>

        <Space direction="vertical" size={12}>
          <RangePicker onChange={onChange} onOk={onOk} locale={locale} />
        </Space>

        <br></br>

        <br></br>

        <button class="button-34" role="button" onClick={getOutPDF}>
          {" "}
          生成生产汇总单
        </button>
        <br></br>
        <br></br>
        <br></br>
        <Table dataSource={dS} columns={columns} />
      </section>

      <footer>
        <p>@2023三赢库存</p>
      </footer>
    </div>
  );
};

export default Summary;
