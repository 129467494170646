import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import { loggedInUser } from "../atom/globalState";
import { getCurrentUser } from "../util/ApiUtil";
import moment from "moment";

import "./Profile.css";
import "../homepage/Home.css";

const Profile = (props) => {
  const [currentUser, setLoggedInUser] = useRecoilState(loggedInUser);

  useEffect(() => {
    if (localStorage.getItem("accessToken") === null) {
      props.history.push("/login");
    }
    loadCurrentUser();
  }, []);

  const loadCurrentUser = () => {
    getCurrentUser()
      .then((response) => {
        console.log("loadCurrentUser");
        console.log(response);
        setLoggedInUser(response);
        console.log(currentUser);
      })
      .catch((error) => {
        const code = error.status;
        props.history.push("/error/" + code);
        console.log(error);
      });
  };

  const logout = () => {
    localStorage.removeItem("accessToken");
    props.history.push("/login");
  };

  return (
    <div class="background-color">
      <nav class="nav-container">
        <a class="logo" href="/Stock">
          {" "}
          三赢库存记录 | {currentUser.username}
        </a>
        <div class="nav-item">
          <a href="/StockIn">入库</a>
          <a href="/StockOut">出库</a>
          <a href="/StockInRecord">入货单记录</a>
          <a href="/StockOutRecord">出货单记录</a>
          <a href="/Stock">库存</a>
          <a href="/Summary">生产汇总</a>
          <a href="#" onClick={logout}>
            退出{" "}
          </a>
        </div>
      </nav>

      <section class="emp-profile">
        <h1>个人资料</h1>
        <a href="/changepassword" className="btn btn-warning btn-mid mr-3">
          修改密码
        </a>

        <table>
          <tr>
            <td>用户ID：</td>
            <td>{currentUser.id}</td>
          </tr>
          <tr>
            <td>用户名：</td>
            <td>{currentUser.username}</td>
          </tr>
          <tr>
            <td>邮箱：</td>
            <td>{currentUser.email}</td>
          </tr>
          <tr>
            <td>地址：</td>
            <td>{currentUser.address}</td>
          </tr>
          <tr>
            <td>创建时间：</td>
            <td>{moment(currentUser.createTime).format("YYYY-MM-DD")}</td>
          </tr>
        </table>
      </section>
      <footer>
        <p>@2023三赢库存</p>
      </footer>
    </div>
  );
};

export default Profile;
