import React, { useEffect } from "react";
import { Form, Input, Button, notification, Select } from "antd";
import { useRecoilState } from "recoil";
import { loggedInUser, stockOut } from "../atom/globalState";
import { addStockOut, getAllStock } from "../util/ApiUtil";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { DatePicker, Space, Cascader, InputNumber } from "antd";
import locale from "antd/es/date-picker/locale/zh_CN";
import "moment/locale/zh-cn";
// import moment from "moment";
// moment.locale("zh-cn");
const { Option } = Select;
const StockOut = (props) => {
  const [currentUser, setLoggedInUser] = useRecoilState(loggedInUser);
  const [stockRecords, setStockRecords] = useRecoilState(stockOut);
  // const [totalWeight, setTotalWeight]
  var totalStockWeight = 0;

  useEffect(() => {
    if (localStorage.getItem("accessToken") === null) {
      props.history.push("/login");
    }
    loadStocks();
  }, []);

  const calTotal = (values) => {
    console.log(values.users);
    if (
      values.users === undefined ||
      values.users === null ||
      values.users.length === 0
    ) {
      totalStockWeight = 0;
      // return;
    } else {
      var cur = 0;
      for (var i = 0; i < values.users.length; i++) {
        cur += values.users[i].weight;
      }
      console.log(cur);
      totalStockWeight = cur;
    }
    console.log(totalStockWeight);
  };
  const addProduct = (values) => {
    var materials;
    if (
      values.users === undefined ||
      values.users === null ||
      values.users.length === 0
    ) {
      materials = [];
      // return;
    } else {
      materials = values.users.map((item) => ({
        note: item.note,
        sourceBatch: item.sourceBatch,
        type: Array.isArray(item.type)
          ? item.type[item.type.length - 1]
          : item.type,
        weight: item.weight,
      }));
    }

    const info = {
      batchNumber: values.batchNumber,
      cost: values.cost,
      electricityUsed: values.electricityUsed,
      keeping: values.keeping,
      operating: values.operating,
      packaging: values.packaging,
      picking: values.picking,
      processTime: values.processTime,
      production: values.production,
      stockInVolume: values.stockInVolume,
      stockUsed: values.stockUsed,
      type: values.type,
      materials: materials,
      date: values.date,
      manDate: values.manDate,
      note: values.note,
      status: "processing",
    };
    addStockOut(info)
      .then((response) => {
        notification.success({
          message: "Success",
          description: "成功提交出货单",
        });

        props.history.push("/");
      })
      .catch((error) => {
        notification.error({
          message: "Error",
          description: error.message || "错误（可能库存不足）！",
        });
      });
  };

  const loadStocks = () => {
    getAllStock()
      .then((response) => {
        setStockRecords(response);
      })
      .catch((error) => {
        console.log(error);
        const code = error.status;
        props.history.push("/error/" + code);
      });
  };
  const formItemLayout = {
    labelCol: {
      xs: {
        span: 12,
      },
      sm: {
        span: 4,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 14,
      },
    },
  };

  const logout = () => {
    localStorage.removeItem("accessToken");
    props.history.push("/login");
  };

  const config = {
    rules: [
      {
        type: "object",
        required: true,
        message: "请填写执行日期！",
      },
    ],
  };
  const configMan = {
    rules: [
      {
        type: "object",
        required: false,
        message: "请填写生产日期！",
      },
    ],
  };
  return (
    <div class="background-color">
      <nav class="nav-container">
        <a class="logo" href="/Stock">
          {" "}
          三赢库存系统 | {currentUser.username}
        </a>

        <div class="nav-item">
          <a href="/StockIn">入库</a>
          <a href="/StockOut">出库</a>
          <a href="/StockInRecord">入货单记录</a>
          <a href="/StockOutRecord">出货单记录</a>
          <a href="/Stock">库存</a>
          <a href="/Summary">生产汇总</a>
          <a href="#" onClick={logout}>
            退出{" "}
          </a>
        </div>
      </nav>

      <section>
        <h1 class="label">出库单</h1>
        <hr class="solid"></hr>
        <div>
          <div class="content">
            <Form
              // {...formItemLayout}
              name="normal_login"
              className="login-form"
              // layout="inline"
              style={{ width: 800 }}
              initialValues={{ remember: true }}
              onFinish={addProduct}
            >
              <Form.Item
                {...formItemLayout}
                name="date"
                label="执行日期"
                {...config}
              >
                <DatePicker locale={locale} />
              </Form.Item>

              <Form.Item
                {...formItemLayout}
                name="type"
                label="产品名称"
                style={{ width: 800 }}
                rules={[
                  {
                    required: true,
                    message: "请填写材料名称！",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                {...formItemLayout}
                name="batchNumber"
                label="生产批号"
                rules={[
                  {
                    required: true,
                    message: "请填写生产批号！",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                {...formItemLayout}
                name="production"
                label="预计产量(公斤)"
                rules={[
                  {
                    required: true,
                    message: "请填写大于零的生产量！",
                    type: "number",
                    min: 0,
                  },
                ]}
              >
                <InputNumber locale={locale} style={{ width: 467 }} />
              </Form.Item>

              <Form.Item
                {...formItemLayout}
                name="packaging"
                label="包装要求"
                rules={[{ required: true, message: "请选择包装要求！" }]}
              >
                <Select allowClear>
                  <Option value="有字">有字</Option>
                  <Option value="无字">无字</Option>
                  <Option value="吨袋有字">吨袋有字</Option>
                  <Option value="吨袋无字">吨袋无字</Option>
                  <Option value="其他">其他</Option>
                </Select>
              </Form.Item>

              <Form.List
                name="users"
                style={
                  {
                    // display: "flex",
                    // width: 200,
                    // paddingright: 200,
                  }
                }
              >
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space
                        key={key}
                        style={{
                          // display: "flex",
                          marginBottom: 10,
                        }}
                      >
                        <p>
                          &nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;
                        </p>

                        <Form.Item
                          name={[name, "type"]}
                          // style={{ width: "80px" }}
                          style={{ marginBottom: "0px" }}
                          label="材料名称"
                          rules={[
                            {
                              required: true,
                              message: "请填写材料名称！",
                            },
                          ]}
                        >
                          <Cascader
                            style={{ width: "190px" }}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            options={[
                              {
                                value: "原料",
                                label: "原料",
                                children:
                                  stockRecords != null
                                    ? stockRecords
                                        .filter((search) =>
                                          search.category.includes("原料")
                                        )
                                        .map((item) => ({
                                          value: item.stockName,
                                          label: item.stockName,
                                        }))
                                    : null,
                              },
                              {
                                value: "再生料",
                                label: "再生料",
                                children:
                                  stockRecords != null
                                    ? stockRecords
                                        .filter((search) =>
                                          search.category.includes("再生料")
                                        )
                                        .map((item) => ({
                                          value: item.stockName,
                                          label: item.stockName,
                                        }))
                                    : null,
                              },
                              {
                                value: "蜡",
                                label: "蜡",
                                children:
                                  stockRecords != null
                                    ? stockRecords
                                        .filter((search) =>
                                          search.category.includes("蜡")
                                        )
                                        .map((item) => ({
                                          value: item.stockName,
                                          label: item.stockName,
                                        }))
                                    : null,
                              },
                              {
                                value: "助剂",
                                label: "助剂",
                                children:
                                  stockRecords != null
                                    ? stockRecords
                                        .filter((search) =>
                                          search.category.includes("助剂")
                                        )
                                        .map((item) => ({
                                          value: item.stockName,
                                          label: item.stockName,
                                        }))
                                    : null,
                              },
                              {
                                value: "降解料",
                                label: "降解料",
                                children:
                                  stockRecords != null
                                    ? stockRecords
                                        .filter((search) =>
                                          search.category.includes("降解料")
                                        )
                                        .map((item) => ({
                                          value: item.stockName,
                                          label: item.stockName,
                                        }))
                                    : null,
                              },
                              {
                                value: "降解助剂",
                                label: "降解助剂",
                                children:
                                  stockRecords != null
                                    ? stockRecords
                                        .filter((search) =>
                                          search.category.includes("降解助剂")
                                        )
                                        .map((item) => ({
                                          value: item.stockName,
                                          label: item.stockName,
                                        }))
                                    : null,
                              },
                              {
                                value: "黄",
                                label: "黄",
                                children:
                                  stockRecords != null
                                    ? stockRecords
                                        .filter((search) =>
                                          search.category.includes("黄")
                                        )
                                        .map((item) => ({
                                          value: item.stockName,
                                          label: item.stockName,
                                        }))
                                    : null,
                              },
                              {
                                value: "桔",
                                label: "桔",
                                children:
                                  stockRecords != null
                                    ? stockRecords
                                        .filter((search) =>
                                          search.category.includes("桔")
                                        )
                                        .map((item) => ({
                                          value: item.stockName,
                                          label: item.stockName,
                                        }))
                                    : null,
                              },
                              {
                                value: "红",
                                label: "红",
                                children:
                                  stockRecords != null
                                    ? stockRecords
                                        .filter((search) =>
                                          search.category.includes("红")
                                        )
                                        .map((item) => ({
                                          value: item.stockName,
                                          label: item.stockName,
                                        }))
                                    : null,
                              },
                              {
                                value: "紫",
                                label: "紫",
                                children:
                                  stockRecords != null
                                    ? stockRecords
                                        .filter((search) =>
                                          search.category.includes("紫")
                                        )
                                        .map((item) => ({
                                          value: item.stockName,
                                          label: item.stockName,
                                        }))
                                    : null,
                              },
                              {
                                value: "蓝",
                                label: "蓝",
                                children:
                                  stockRecords != null
                                    ? stockRecords
                                        .filter((search) =>
                                          search.category.includes("蓝")
                                        )
                                        .map((item) => ({
                                          value: item.stockName,
                                          label: item.stockName,
                                        }))
                                    : null,
                              },
                              {
                                value: "绿",
                                label: "绿",
                                children:
                                  stockRecords != null
                                    ? stockRecords
                                        .filter((search) =>
                                          search.category.includes("绿")
                                        )
                                        .map((item) => ({
                                          value: item.stockName,
                                          label: item.stockName,
                                        }))
                                    : null,
                              },
                              {
                                value: "白",
                                label: "白",
                                children:
                                  stockRecords != null
                                    ? stockRecords
                                        .filter((search) =>
                                          search.category.includes("白")
                                        )
                                        .map((item) => ({
                                          value: item.stockName,
                                          label: item.stockName,
                                        }))
                                    : null,
                              },
                              {
                                value: "灰",
                                label: "灰",
                                children:
                                  stockRecords != null
                                    ? stockRecords
                                        .filter((search) =>
                                          search.category.includes("灰")
                                        )
                                        .map((item) => ({
                                          value: item.stockName,
                                          label: item.stockName,
                                        }))
                                    : null,
                              },
                              {
                                value: "黑",
                                label: "黑",
                                children:
                                  stockRecords != null
                                    ? stockRecords
                                        .filter((search) =>
                                          search.category.includes("黑")
                                        )
                                        .map((item) => ({
                                          value: item.stockName,
                                          label: item.stockName,
                                        }))
                                    : null,
                              },
                              {
                                value: "包装袋",
                                label: "包装袋",
                                children:
                                  stockRecords != null
                                    ? stockRecords
                                        .filter((search) =>
                                          search.category.includes("包装袋")
                                        )
                                        .map((item) => ({
                                          value: item.stockName,
                                          label: item.stockName,
                                        }))
                                    : null,
                              },
                              {
                                value: "其他",
                                label: "其他",
                                children:
                                  stockRecords != null
                                    ? stockRecords
                                        .filter((search) =>
                                          search.category.includes("其他")
                                        )
                                        .map((item) => ({
                                          value: item.stockName,
                                          label: item.stockName,
                                        }))
                                    : null,
                              },
                            ]}
                          />
                        </Form.Item>

                        <Form.Item
                          name={[name, "weight"]}
                          style={{ marginBottom: "0px" }}
                          label="重量"
                          rules={[
                            {
                              required: true,
                              message: "请填写大于零的重量（公斤）！",
                              type: "number",
                              min: 0,
                            },
                          ]}
                        >
                          <InputNumber locale={locale} />
                        </Form.Item>
                        <Form.Item
                          name={[name, "note"]}
                          label="备注"
                          style={{ marginBottom: "0px", width: "10" }}
                        >
                          <Input style={{ width: 177 }} />
                        </Form.Item>

                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        // block
                        icon={<PlusOutlined />}
                      >
                        添加原材料
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>

              <Form.Item name="picking" label="出库领料人" {...formItemLayout}>
                <Input />
              </Form.Item>

              <Form.Item name="keeping" label="生产保管员" {...formItemLayout}>
                <Input />
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                name="manDate"
                label="生产日期"
                {...configMan}
              >
                <DatePicker locale={locale} />
              </Form.Item>

              <Form.Item
                name="operating"
                label="生产操作人"
                {...formItemLayout}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="processTime"
                {...formItemLayout}
                label="加工用时间(小时)"
                rules={[{ type: "number", min: 0 }]}
              >
                <InputNumber locale={locale} style={{ width: 467 }} />
              </Form.Item>
              <Form.Item
                name="electricityUsed"
                {...formItemLayout}
                label="加工用电量(千瓦时)"
                rules={[{ type: "number", min: 0 }]}
              >
                <InputNumber locale={locale} style={{ width: 467 }} />
              </Form.Item>
              <Form.Item
                name="stockInVolume"
                {...formItemLayout}
                label="成品入库量(公斤)"
                rules={[{ type: "number", min: 0 }]}
              >
                <InputNumber locale={locale} style={{ width: 467 }} />
              </Form.Item>
              {/* <Form.Item
                name="stockUsed"
                {...formItemLayout}
                label="产品消耗量"
                rules={[{ type: "number", min: 0 }]}
              >
                <InputNumber locale={locale} style={{ width: 467 }} />
              </Form.Item> */}

              <Form.Item name="note" label="备注" {...formItemLayout}>
                <Input />
              </Form.Item>

              <Form.Item>
                <Button
                  shape="round"
                  htmlType="submit"
                  className="login-form-button"
                >
                  提交
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </section>
      <footer>
        <p>@2023三赢库存</p>
      </footer>
    </div>
  );
};

export default StockOut;
