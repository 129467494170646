import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import { loggedInUser, stockInRecords } from "../atom/globalState";
import { getAllAdjustRecords } from "../util/ApiUtil";
import "./Home.css";
import { Table } from "antd";
import { useState } from "react";
import moment from "moment";
const StockAdjustRecord = (props) => {
  const [currentUser, setLoggedInUser] = useRecoilState(loggedInUser);
  const [stockRecords, setStockRecords] = useRecoilState(stockInRecords);
  const [query, setQuery] = useState("");

  const logout = () => {
    localStorage.removeItem("accessToken");
    props.history.push("/login");
  };

  useEffect(() => {
    if (localStorage.getItem("accessToken") === null) {
      props.history.push("/login");
    }

    if (localStorage.getItem("name") !== "Admin") {
      props.history.push("/stock");
    }

    loadRecords();
  }, []);

  const loadRecords = () => {
    getAllAdjustRecords()
      .then((response) => {
        console.log(response);
        setStockRecords(response);
      })
      .catch((error) => {
        console.log(error);
        const code = error.status;
        props.history.push("/error/" + code);
      });
  };

  const dS = stockRecords
    .filter(
      (search) =>
        (search.type != null
          ? search.type.toLowerCase().includes(query)
          : null) ||
        (search.adjustStockPerson != null
          ? search.adjustStockPerson.toLowerCase().includes(query)
          : null)
    )
    .map((record) => ({
      key: "1",
      材料名称: record.type,
      调库量: record.adjustStockVolume,
      调库人: record.adjustStockPerson != null ? record.adjustStockPerson : "-",
      调库时间: moment(record.date).format("YYYY-MM-DD"),
      备注: record.note != null ? record.note : "-",
    }));

  const columns = [
    {
      title: "材料名称",
      dataIndex: "材料名称",
      key: "材料名称",
    },

    {
      title: "调库量",
      dataIndex: "调库量",
      key: "调库量",
    },

    {
      title: "调库人",
      dataIndex: "调库人",
      key: "调库人",
    },
    {
      title: "调库时间",
      dataIndex: "调库时间",
      key: "调库事件",
    },
    {
      title: "备注",
      dataIndex: "备注",
      key: "备注",
    },
  ];

  return (
    <div class="background-color">
      <nav class="nav-container">
        <a class="logo" href="/Stock">
          {" "}
          三赢库存系统 | {currentUser.username}
        </a>

        <div class="nav-item">
          <a href="/StockAdmin">库存</a>
          <a href="/stockAdjust">调库</a>
          <a href="/StockAdjustRecord">调库单记录</a>
          <a href="/userManagement">用户管理</a>

          <a href="#" onClick={logout}>
            退出{" "}
          </a>
        </div>
      </nav>

      <section class="product">
        <h1>入货单记录</h1>
        <input
          type="text"
          placeholder="搜索材料名称或者调库人"
          className="form-control"
          id="search-engine"
          style={{ width: "50" }}
          onChange={(e) => setQuery(e.target.value)}
        ></input>
        <br></br>
        <br></br>
        <br></br>
        <Table dataSource={dS} columns={columns} />
      </section>

      <footer>
        <p>@2023三赢库存</p>
      </footer>
    </div>
  );
};

export default StockAdjustRecord;
