import React, { useEffect, useState } from "react";

import "./error.css";
const Error = (props) => {
  const code = "https://http.dog/" + props.match.params.errorCode + ".jpg";
  useEffect(() => {
    console.log(props.match.params.errorCode);
    if (props.match.params.errorCode === "undefined") {
      props.history.push("/login");
    }
  }, []);

  const logout = () => {
    localStorage.removeItem("accessToken");
    props.history.push("/login");
  };
  return (
    <div class="error">
      <nav class="error-navbar">
        <a href="/">主页</a>
        <a href="#" onClick={logout}>
          退出
        </a>
      </nav>
      <h1>登陆过期，请重新登陆</h1>

      <img src={code} alt="unauthrozied" />
    </div>
  );
};

export default Error;
