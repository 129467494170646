import { atom } from "recoil";

export const loggedInUser = atom({
  key: "loggedInUser",
  default: {},
  persistence_UNSTABLE: {
    type: "loggedInUser",
  },
});

export const loadProduct = atom({
  key: "loadProduct",
  default: [],
  persistence_UNSTABLE: {
    type: "loadProduct",
  },
});

export const loadUsers = atom({
  key: "loadUsers",
  default: [],
  persistence_UNSTABLE: {
    type: "loadUsers",
  },
});

export const loadSingleProduct = atom({
  key: "loadSingleProduct",
  default: [],
  persistence_UNSTABLE: {
    type: "loadSingleProduct",
  },
});

export const chatActiveContact = atom({
  key: "chatActiveContact",
  persistence_UNSTABLE: {
    type: "chatActiveContact",
  },
});

export const chatMessages = atom({
  key: "chatMessages",
  default: [],
  persistence_UNSTABLE: {
    type: "chatMessages",
  },
});

export const productsI = atom({
  key: "productsI",
  default: [],
  persistence_UNSTABLE: {
    type: "productsI",
  },
});

export const recordss = atom({
  key: "recordss",
  default: [],
  persistence_UNSTABLE: {
    type: "recordss",
  },
});

export const stocks = atom({
  key: "stocks",
  default: [],
  persistence_UNSTABLE: {
    type: "stocks",
  },
});
export const stockInRecords = atom({
  key: "stockInRecords",
  default: [],
  persistence_UNSTABLE: {
    type: "stockInRecords",
  },
});
export const summary = atom({
  key: "summary",
  default: [],
  persistence_UNSTABLE: {
    type: "summary",
  },
});

export const stockOut = atom({
  key: "stockOut",
  default: [],
  persistence_UNSTABLE: {
    type: "stockOut",
  },
});

export const stockOutRecords = atom({
  key: "stockOutRecords",
  default: [],
  persistence_UNSTABLE: {
    type: "stockOutRecords",
  },
});
