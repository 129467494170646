import React, { useEffect, useState } from "react";
import { Form, Input, Button, notification, Select } from "antd";
import { useRecoilState } from "recoil";
import { loggedInUser, recordss } from "../atom/globalState";
import { getAllStock, adjustStock } from "../util/ApiUtil";
import { Cascader, InputNumber } from "antd";
import locale from "antd/es/date-picker/locale/zh_CN";
import { Radio } from "antd";

const StockAdjust = (props) => {
  const [currentUser, setLoggedInUser] = useRecoilState(loggedInUser);
  const [stockRecords, setStockRecords] = useRecoilState(recordss);
  const [form] = Form.useForm();

  const loadStocks = () => {
    getAllStock()
      .then((response) => {
        console.log(stockRecords);
        setStockRecords(response);
      })
      .catch((error) => {
        console.log(error);
        const code = error.status;
        props.history.push("/error/" + code);
      });
  };

  useEffect(() => {
    if (localStorage.getItem("accessToken") === null) {
      props.history.push("/login");
    }
    if (localStorage.getItem("name") !== "Admin") {
      props.history.push("/error/401");
    }
    loadStocks();
  }, []);

  const _addStock = (values) => {
    console.log(values);

    const info = {
      adjustStockPerson: values.adjustStockPerson,
      adjustStockVolume: values.adjustStockVolume,
      type: Array.isArray(values.type)
        ? values.type[values.type.length - 1]
        : values.type,
      note: values.note,
    };
    console.log(info);
    adjustStock(info)
      .then((response) => {
        notification.success({
          message: "Success",
          description: "调库成功！",
        });
        props.history.push("/");
      })
      .catch((error) => {
        notification.error({
          message: "Error",
          description: error.message || "提交失败！",
        });
      });
  };

  const logout = () => {
    localStorage.removeItem("accessToken");
    props.history.push("/login");
  };

  return (
    <div class="background-color">
      <nav class="nav-container">
        <a class="logo" href="/Stock">
          {" "}
          三赢库存系统 | {currentUser.username}
        </a>

        <div class="nav-item">
          <a href="/StockAdmin">库存</a>
          <a href="/stockAdjust">调库</a>
          <a href="/StockAdjustRecord">调库单记录</a>
          <a href="/userManagement">用户管理</a>
          <a href="#" onClick={logout}>
            退出
          </a>
        </div>
      </nav>

      <section>
        <h1 class="label">调库单</h1>
        <hr class="solid"></hr>
        <div>
          <div class="content">
            <Form
              form={form}
              style={{ width: 400 }}
              layout="vertical"
              onFinish={_addStock}
            >
              <Form.Item
                name="type"
                label="材料名称"
                rules={[
                  {
                    required: true,
                    message: "请填写材料名称！",
                  },
                ]}
              >
                <Cascader
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: "原料",
                      label: "原料",
                      children:
                        stockRecords != null
                          ? stockRecords
                              .filter((search) =>
                                search.category.includes("原料")
                              )
                              .map((item) => ({
                                value: item.stockName,
                                label: item.stockName,
                              }))
                          : null,
                    },
                    {
                      value: "再生料",
                      label: "再生料",
                      children:
                        stockRecords != null
                          ? stockRecords
                              .filter((search) =>
                                search.category.includes("再生料")
                              )
                              .map((item) => ({
                                value: item.stockName,
                                label: item.stockName,
                              }))
                          : null,
                    },
                    {
                      value: "蜡",
                      label: "蜡",
                      children:
                        stockRecords != null
                          ? stockRecords
                              .filter((search) =>
                                search.category.includes("蜡")
                              )
                              .map((item) => ({
                                value: item.stockName,
                                label: item.stockName,
                              }))
                          : null,
                    },
                    {
                      value: "助剂",
                      label: "助剂",
                      children:
                        stockRecords != null
                          ? stockRecords
                              .filter((search) =>
                                search.category.includes("助剂")
                              )
                              .map((item) => ({
                                value: item.stockName,
                                label: item.stockName,
                              }))
                          : null,
                    },
                    {
                      value: "降解料",
                      label: "降解料",
                      children:
                        stockRecords != null
                          ? stockRecords
                              .filter((search) =>
                                search.category.includes("降解料")
                              )
                              .map((item) => ({
                                value: item.stockName,
                                label: item.stockName,
                              }))
                          : null,
                    },
                    {
                      value: "降解助剂",
                      label: "降解助剂",
                      children:
                        stockRecords != null
                          ? stockRecords
                              .filter((search) =>
                                search.category.includes("降解助剂")
                              )
                              .map((item) => ({
                                value: item.stockName,
                                label: item.stockName,
                              }))
                          : null,
                    },
                    {
                      value: "黄",
                      label: "黄",
                      children:
                        stockRecords != null
                          ? stockRecords
                              .filter((search) =>
                                search.category.includes("黄")
                              )
                              .map((item) => ({
                                value: item.stockName,
                                label: item.stockName,
                              }))
                          : null,
                    },
                    {
                      value: "桔",
                      label: "桔",
                      children:
                        stockRecords != null
                          ? stockRecords
                              .filter((search) =>
                                search.category.includes("桔")
                              )
                              .map((item) => ({
                                value: item.stockName,
                                label: item.stockName,
                              }))
                          : null,
                    },
                    {
                      value: "红",
                      label: "红",
                      children:
                        stockRecords != null
                          ? stockRecords
                              .filter((search) =>
                                search.category.includes("红")
                              )
                              .map((item) => ({
                                value: item.stockName,
                                label: item.stockName,
                              }))
                          : null,
                    },
                    {
                      value: "紫",
                      label: "紫",
                      children:
                        stockRecords != null
                          ? stockRecords
                              .filter((search) =>
                                search.category.includes("紫")
                              )
                              .map((item) => ({
                                value: item.stockName,
                                label: item.stockName,
                              }))
                          : null,
                    },
                    {
                      value: "蓝",
                      label: "蓝",
                      children:
                        stockRecords != null
                          ? stockRecords
                              .filter((search) =>
                                search.category.includes("蓝")
                              )
                              .map((item) => ({
                                value: item.stockName,
                                label: item.stockName,
                              }))
                          : null,
                    },
                    {
                      value: "绿",
                      label: "绿",
                      children:
                        stockRecords != null
                          ? stockRecords
                              .filter((search) =>
                                search.category.includes("绿")
                              )
                              .map((item) => ({
                                value: item.stockName,
                                label: item.stockName,
                              }))
                          : null,
                    },
                    {
                      value: "白",
                      label: "白",
                      children:
                        stockRecords != null
                          ? stockRecords
                              .filter((search) =>
                                search.category.includes("白")
                              )
                              .map((item) => ({
                                value: item.stockName,
                                label: item.stockName,
                              }))
                          : null,
                    },
                    {
                      value: "灰",
                      label: "灰",
                      children:
                        stockRecords != null
                          ? stockRecords
                              .filter((search) =>
                                search.category.includes("灰")
                              )
                              .map((item) => ({
                                value: item.stockName,
                                label: item.stockName,
                              }))
                          : null,
                    },
                    {
                      value: "黑",
                      label: "黑",
                      children:
                        stockRecords != null
                          ? stockRecords
                              .filter((search) =>
                                search.category.includes("黑")
                              )
                              .map((item) => ({
                                value: item.stockName,
                                label: item.stockName,
                              }))
                          : null,
                    },
                    {
                      value: "包装袋",
                      label: "包装袋",
                      children:
                        stockRecords != null
                          ? stockRecords
                              .filter((search) =>
                                search.category.includes("包装袋")
                              )
                              .map((item) => ({
                                value: item.stockName,
                                label: item.stockName,
                              }))
                          : null,
                    },
                    {
                      value: "其他",
                      label: "其他",
                      children:
                        stockRecords != null
                          ? stockRecords
                              .filter((search) =>
                                search.category.includes("其他")
                              )
                              .map((item) => ({
                                value: item.stockName,
                                label: item.stockName,
                              }))
                          : null,
                    },
                  ]}
                />
              </Form.Item>

              <Form.Item
                name="adjustStockVolume"
                label="调库量"
                rules={[
                  {
                    required: true,
                    message: "请填写大于零的调库量！",
                    type: "number",
                    min: 0,
                  },
                ]}
              >
                <InputNumber locale={locale} style={{ width: 400 }} />
              </Form.Item>

              <Form.Item name="adjustStockPerson" label="调库人">
                <Input />
              </Form.Item>

              <Form.Item name="note" label="备注">
                <Input />
              </Form.Item>

              <Form.Item>
                <Button
                  shape="round"
                  htmlType="submit"
                  className="login-form-button"
                >
                  提交
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </section>
      <footer>
        <p>@2023三赢库存</p>
      </footer>
    </div>
  );
};

export default StockAdjust;
