import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { loggedInUser } from "../atom/globalState";
import moment from "moment";
import {
  Table,
  Form,
  Input,
  InputNumber,
  Button,
  notification,
  DatePicker,
} from "antd";
import {
  getStockOutById,
  editStockOut,
  getStockOutPDF,
  withdrawOutRecord,
} from "../util/ApiUtil";
import locale from "antd/es/date-picker/locale/zh_CN";

const OutRecordDetail = (props) => {
  const [currentUser] = useRecoilState(loggedInUser);
  const [myProduct, setMyProduct] = useState({});
  const [materials, setMaterials] = useState([]);
  const recordId = props.match.params.id;

  useEffect(() => {
    if (localStorage.getItem("accessToken") === null) {
      props.history.push("/login");
    }
    console.log("show the productId: " + recordId);
    loadStock();
  }, []);

  const logout = () => {
    localStorage.removeItem("accessToken");
    props.history.push("/login");
  };

  const loadStock = () => {
    const info = {
      id: recordId,
      username: recordId,
    };
    getStockOutById(info)
      .then((response) => {
        console.log("getSingleProduct");
        console.log(response);
        setMyProduct(response);
        setMaterials(response.materials);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //if its admin return admin navbar
  const loadNavforAdmin = () => {
    let navigation = (
      <nav class="nav-container">
        <p> 三赢库存记录 | Admin </p>
        <div class="nav-item">
          <a href="/StockIn">入库</a>
          <a href="/StockOut">出库</a>
          <a href="/StockInRecord">入货单记录</a>
          <a href="/StockOutRecord">出货单记录</a>
          <a href="/Stock">库存</a>
          <a href="/Summary">生产汇总</a>
          <a href="#" onClick={logout}>
            退出{" "}
          </a>
        </div>
      </nav>
    );
    let userNav = (
      <nav class="nav-container">
        <a class="logo" href="/Stock">
          {" "}
          三赢库存记录 | {currentUser.username}
        </a>
        <div class="nav-item">
          <div class="nav-item">
            <a href="/StockIn">入库</a>
            <a href="/StockOut">出库</a>
            <a href="/StockInRecord">入货单记录</a>
            <a href="/StockOutRecord">出货单记录</a>
            <a href="/Stock">库存</a>
            <a href="/Summary">生产汇总</a>
            <a href="#" onClick={logout}>
              退出{" "}
            </a>
          </div>
        </div>
      </nav>
    );
    if (currentUser.username === "Admin") {
      return navigation;
    } else return userNav;
  };

  const dS =
    materials === null
      ? []
      : materials.map((record) => ({
          key: "1",
          材料名称: record.type,
          重量: record.weight,
          备注: record.note != null ? record.note : "-",
        }));

  const columns = [
    {
      title: "材料名称",
      dataIndex: "材料名称",
      key: "材料名称",
    },

    {
      title: "重量",
      dataIndex: "重量",
      key: "重量",
    },

    {
      title: "备注",
      dataIndex: "备注",
      key: "备注",
    },
  ];

  const completeRecord = (values) => {
    console.log(values);

    const info = {
      cost: values.cost == null ? myProduct.cost : values.cost,
      electricityUsed:
        values.electricityUsed == null
          ? myProduct.electricityUsed
          : values.electricityUsed,
      keeping: values.keeping == null ? myProduct.keeping : values.keeping,
      note: values.note == null ? myProduct.note : values.note,
      operating:
        values.operating == null ? myProduct.operating : values.operating,
      picking: values.picking == null ? myProduct.picking : values.picking,
      processTime:
        values.processTime == null ? myProduct.processTime : values.processTime,
      stockInVolume:
        values.stockInVolume == null
          ? myProduct.stockInVolume
          : values.stockInVolume,
      stockUsed:
        values.stockUsed == null ? myProduct.stockUsed : values.stockUsed,
      manDate: values.manDate == null ? myProduct.manDate : values.manDate,
      status: "done",
      id: myProduct.id,
    };

    editStockOut(info)
      .then((response) => {
        console.log(response);
        notification.success({
          message: "Success",
          description: "提交成功！",
        });
        props.history.push("/StockOutRecord");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getOutPDF = () => {
    getStockOutPDF(recordId)
      .then((res) => {
        var data = new Blob([res], { type: "application/pdf" });
        var csvURL = window.URL.createObjectURL(data);
        var tempLink = document.createElement("a");
        tempLink.href = csvURL;
        tempLink.setAttribute("download", "出货单.pdf");
        tempLink.click();
      })
      .catch((error) => {
        console.log(error);
        const code = error.status;
        props.history.push("/error/" + code);
      });
  };

  const withdrawRecord = () => {
    withdrawOutRecord(recordId)
      .then((res) => {
        notification.success({
          message: "Success",
          description: "撤销成功！",
        });
        props.history.push("/StockOutRecord");
      })
      .catch((error) => {
        console.log(error);
        const code = error.status;
        props.history.push("/error/" + code);
      });
  };
  return (
    <div class="background-color">
      {loadNavforAdmin()}
      <section>
        {myProduct.status === "processing" ? (
          <button onClick={withdrawRecord} class="button-34">
            撤销出库单
          </button>
        ) : null}

        <h1>出库单详情</h1>
        <br></br>
        <button onClick={getOutPDF} class="button-34">
          生成出库单
        </button>

        <div class="container-cart">
          <table>
            <tr>
              <td>产品名称：</td>
              <td>{myProduct.type}</td>
            </tr>
            <tr>
              <td>生产批号：</td>
              <td>{myProduct.batchNumber}</td>
            </tr>
            <tr>
              <td>执行日期：</td>
              <td> {moment(myProduct.date).format("YYYY-MM-DD")}</td>
            </tr>
            <tr>
              <td>预计产量：</td>
              <td>{myProduct.production}</td>
            </tr>
            <tr>
              <td>包装要求：</td>
              <td>{myProduct.packaging}</td>
            </tr>

            <tr>
              <td>出库领料人：</td>
              <td>{myProduct.picking}</td>
            </tr>
            <tr>
              <td>生产保管员：</td>
              <td>{myProduct.keeping}</td>
            </tr>
            <tr>
              <td>生产日期：</td>
              <td>
                {myProduct.manDate == null
                  ? ""
                  : moment(myProduct.manDate).format("YYYY-MM-DD")}
              </td>
            </tr>
            <tr>
              <td>生产操作人：</td>
              <td>{myProduct.operating}</td>
            </tr>
            <tr>
              <td>加工用时间：</td>
              <td>{myProduct.processTime}</td>
            </tr>
            <tr>
              <td>加工用电量：</td>
              <td>{myProduct.electricityUsed}</td>
            </tr>
            <tr>
              <td>成品入库量：</td>
              <td>{myProduct.stockInVolume}</td>
            </tr>
            {/* <tr>
              <td>产品消耗量：</td>
              <td>{myProduct.stockUsed}</td>
            </tr> */}
            {/* <tr>
              <td>成本核算：</td>
              <td>{myProduct.cost}</td>
            </tr> */}
            <tr>
              <td>备注：</td>
              <td>{myProduct.note}</td>
            </tr>
          </table>
        </div>

        <h1 style={{ marginTop: "30px" }}>原材料</h1>
        <br></br>
        <br></br>
        <div>
          <Table dataSource={dS} columns={columns} />
        </div>

        <br></br>
        <br></br>
        <br></br>

        {myProduct.status === "processing" ? (
          <h1 class="label">完成出货单</h1>
        ) : null}
        <br></br>
        <br></br>
        {myProduct.status === "processing" ? (
          <div class="content">
            <Form
              name="normal_login"
              className="login-form"
              style={{ width: 800 }}
              initialValues={{ remember: true }}
              onFinish={completeRecord}
            >
              {myProduct.picking === null ? (
                <Form.Item
                  name="picking"
                  label="出库领料人"
                  rules={[
                    {
                      required: true,
                      message: "请填写出库领料人！",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              ) : null}
              {myProduct.keeping === null ? (
                <Form.Item
                  name="keeping"
                  label="生产保管员"
                  rules={[
                    {
                      required: true,
                      message: "请填写生产保管员！",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              ) : null}

              {myProduct.manDate === null ? (
                <Form.Item
                  name="manDate"
                  label="生产日期"
                  rules={[
                    {
                      required: true,
                      message: "请填写生产日期！",
                    },
                  ]}
                >
                  <DatePicker locale={locale} />
                </Form.Item>
              ) : null}

              {myProduct.operating === null ? (
                <Form.Item
                  name="operating"
                  label="生产操作人"
                  rules={[
                    {
                      required: true,
                      message: "请填写生产操作人！",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              ) : null}
              {myProduct.processTime === null ? (
                <Form.Item
                  name="processTime"
                  label="加工用时间(小时)"
                  rules={[
                    {
                      required: true,
                      message: "请填写加工用时间！",
                      type: "number",
                      min: 0,
                    },
                  ]}
                >
                  <InputNumber locale={locale} style={{ width: 467 }} />
                </Form.Item>
              ) : null}
              {myProduct.electricityUsed === null ? (
                <Form.Item
                  name="electricityUsed"
                  label="加工用电量(千瓦时)"
                  rules={[
                    {
                      required: true,
                      message: "请填写加工用电量",
                      type: "number",
                      min: 0,
                    },
                  ]}
                >
                  <InputNumber locale={locale} style={{ width: 467 }} />
                </Form.Item>
              ) : null}
              {myProduct.stockInVolume === null ? (
                <Form.Item
                  name="stockInVolume"
                  label="成品入库量(公斤)"
                  rules={[
                    {
                      type: "number",
                      min: 0,
                      required: true,
                      message: "请填写成品入库量",
                    },
                  ]}
                >
                  <InputNumber locale={locale} style={{ width: 467 }} />
                </Form.Item>
              ) : null}
              {/* {myProduct.stockUsed === null ? (
                <Form.Item
                  name="stockUsed"
                  label="产品消耗量"
                  rules={[
                    {
                      type: "number",
                      min: 0,
                      required: true,
                      message: "请填写产品消耗量",
                    },
                  ]}
                >
                  <InputNumber locale={locale} style={{ width: 467 }} />
                </Form.Item>
              ) : null} */}
              {myProduct.note === null ? (
                <Form.Item name="note" label="备注">
                  <Input />
                </Form.Item>
              ) : null}
              <Form.Item>
                <Button
                  shape="round"
                  htmlType="submit"
                  className="login-form-button"
                >
                  完成
                </Button>
              </Form.Item>
            </Form>
          </div>
        ) : null}
      </section>

      <footer>
        <p>@2023三赢库存</p>
      </footer>
    </div>
  );
};

export default OutRecordDetail;
