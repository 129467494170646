import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { notification } from "antd";
import { loadUsers, loggedInUser } from "../atom/globalState";
import { getUsers, deleteUser, modifyUser } from "../util/ApiUtil";
import moment from "moment";

import "./UserManagement.css";

const UserManagement = (props) => {
  const [users, setUsers] = useRecoilState(loadUsers);
  const [query, setQuery] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [currentUser, setLoggedInUser] = useRecoilState(loggedInUser);

  useEffect(() => {
    if (localStorage.getItem("accessToken") === null) {
      props.history.push("/login");
    }
    loadTotalUsers();
  }, []);

  const loadTotalUsers = () => {
    getUsers()
      .then((response) => {
        setUsers(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const logout = () => {
    localStorage.removeItem("accessToken");
    props.history.push("/login");
  };

  const deleteClick = (value) => {
    console.log(value);
    const info = {
      id: value,
    };
    deleteUser(info)
      .then((response) => {
        notification.success({
          message: "Success",
          description: "成功删除用户!",
        });
        loadTotalUsers();
      })
      .catch((error) => {
        notification.error({
          message: "Error",
          description: error.message || "错误！",
        });
      });
  };

  // Only display content for admin!
  const loadContentforAdmin = () => {
    let content = (
      <div class="product">
        {" "}
        <h1 class="label">用户管理</h1>
        <table class="table">
          <thead>
            <tr>
              <th class="col-md-1">用户名</th>

              <th class="col-md-3">邮箱</th>
              <th class="col-md-2">地址</th>
              <th class="col-md-1">创建时间</th>
              <th class="col-md-1">操作</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr>
                <td>{user.username}</td>

                <td>{user.email}</td>
                <td>{user.address}</td>
                <td>{moment(user.createTime).format("YYYY-MM-DD")}</td>
                <td>
                  <button
                    onClick={() => deleteClick(user.id)}
                    type="button"
                    class="btn btn-danger"
                  >
                    删除
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );

    if (localStorage.getItem("name") === "Admin") {
      return content;
    } else {
      props.history.push("/error/401");
    }
  };

  return (
    <div class="background-color">
      <nav class="nav-container">
        <p> 三赢库存系统 | Admin </p>
        <div class="nav-item">
          <a href="/StockAdmin">库存</a>
          <a href="/stockAdjust">调库</a>
          <a href="/StockAdjustRecord">调库单记录</a>
          <a href="/userManagement">用户管理</a>

          <a href="#" onClick={logout}>
            退出
          </a>
        </div>
      </nav>
      <section>
        <div>{loadContentforAdmin()}</div>
      </section>
      <footer>
        <p>@2023三赢库存</p>
      </footer>
    </div>
  );
};

export default UserManagement;
